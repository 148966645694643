import { useStaticQuery, graphql } from 'gatsby'
import type { GetServicesClubQuery } from '@generated/graphql'

import { DropDownMob } from './DropDownMob'
import './styles.scss'

type Props = {
  name: string
  code: string
}

export const Racquets = () => {
  const database = useStaticQuery<GetServicesClubQuery>(graphql`
    query GetServicesClub {
      allCmsClubeDecathlon(
        filter: { id: { eq: "d4d744ed-6bb4-5276-8107-558dbc877c0f" } }
      ) {
        edges {
          node {
            id
            sections {
              data
              name
            }
          }
        }
      }
    }
  `)

  const {
    allCmsClubeDecathlon: {
      edges: [
        {
          node: {
            sections: [
              {
                data: { warranty },
              },
            ],
          },
        },
      ],
    },
  } = database

  return (
    <section className="racquets_content font-roboto bg-[rgba(0,0,0,.4)] py-0 px-[30px] overflow-hidden">
      <div>
        <div>
          <h2 className="text-2xl font-bold text-white text-center uppercase mb-6 xl:mt-[75px] mt-[120px] mx-0">
            Encordoamento de raquetes
          </h2>
          <p className="text-2xl font-light text-white text-center my-6 mx-0">
            Modelos participantes:
          </p>
        </div>

        <table className="racquets_table flex items-center justify-center border-collapse w-full font-roboto">
          <tbody className="racquets-table-content overflow overflow-auto max-h-[800px] max-w-[538px] w-full">
            <tr>
              <th className="table_title">Modelo</th>
              <th className="table_title">Código</th>
            </tr>
            {warranty.map((item: Props, index: number) => {
              return (
                <tr
                  key={index}
                  className={
                    item.code === undefined && item.name === undefined
                      ? 'hidden'
                      : ''
                  }
                >
                  <td className="max-w-[394px] w-full">{item.name}</td>
                  <td>{item.code}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {warranty.map((item: Props, mobIndex: number) => {
          return (
            <div
              key={mobIndex}
              className={
                item.code === undefined && item.name === undefined
                  ? 'hidden'
                  : ''
              }
            >
              <DropDownMob name={item.name} code={item.code} />
            </div>
          )
        })}
        <div className="max-w-[900px] w-full mx-auto mt-0 mb-[75px] py-5 px-0">
          <p className="text-xs text-white text-center">
            *O cliente tem direito a 1 encordoamento grátis após a compra da
            corda para utilização em até 90 dias. Limitado a um encordoamento
            por CPF. Receberá o vale por e-mail em até uma semana. Importante!
            Para garantir o recebimento do e-mail é preciso concordar em receber
            e-mails da Decathlon.
          </p>
        </div>
      </div>
    </section>
  )
}
