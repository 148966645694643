import { useRef } from 'react'
import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'
import Button from 'src/components/ui/Button'

import { BikesReview } from './BikesReview'
import { Racquets } from './Racquets'
import './styles.scss'

const Servicos = () => {
  const benefitsSportsBike = useRef<null | HTMLDivElement>(null)
  const benefitsSportsRacquet = useRef<null | HTMLDivElement>(null)
  const scrollToDiv = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className="club-services_container">
      <div className="club-services_content flex items-center justify-center flex-col bg-[rgba(0,0,0,.4)]">
        <div className="flex items-center justify-center">
          <Image
            className="min-w-[228px] club-services_logo"
            src={stripUrl(
              'https://decathlonstore.vteximg.com.br/arquivos/clube-dctl.png'
            )}
            alt="clube-services-ico"
            title="clube-services-ico"
            loading="lazy"
            width={270}
            height={127}
          />
        </div>
        <div>
          <h2 className="text-xl vs:text-lg font-bold font-roboto text-center uppercase tracking-[6px] text-white my-4 mx-0">
            Mais benefícios
            <br />
            Mais esporte
          </h2>
        </div>
        <div className="flex items-center justify-center rounded-[50%] w-28 h-28 bg-gradient-to-t from-[#296ffa] to-[#0073b3] mt-10">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/stars-ico.png"
            alt="clube-services-ico"
            loading="lazy"
            width={60}
            height={60}
          />
        </div>
        <div>
          <h1 className="text-3xl text-center uppercase text-white font-roboto my-5 mx-0 font-bold">
            Serviços Exclusivos
          </h1>
          <p className="text-[21px] leading-none text-center text-white font-roboto my-5 mx-0">
            Clientes Clube Decathlon tem direito a serviços na compra de bikes e
            cordas!
          </p>
        </div>
        <div className="flex items-center justify-center flex-wrap gap-7 mt-12 mb-5">
          <Button
            type="button"
            aria-label="Direcionar para revisão de bikes"
            className="bike-button relative pointer text-sm text-white max-h-10 !py-[7px] !px-[14px] pp:mx-auto md:mx-0 font-bold w-96 uppercase border-[3px] border-white tracking-[5px] font-roboto vs:text-sm vs:w-[312px] vs:tracking-[0]"
            onClick={() => scrollToDiv(benefitsSportsBike)}
          >
            Revisão de Bikes*
          </Button>
          <Button
            type="button"
            aria-label="Direcionar para encordoamento de raquetes"
            className="racket-button relative pointer text-sm text-white max-h-10 !py-[7px] !px-[14px] pp:mx-auto md:mx-0 font-bold w-96 uppercase border-[3px] border-white tracking-[5px] font-roboto vs:text-sm vs:w-[312px] vs:tracking-[0]"
            onClick={() => scrollToDiv(benefitsSportsRacquet)}
          >
            Encordoamento de raquetes*
          </Button>
        </div>
      </div>
      <div className="bikes-review_container" ref={benefitsSportsBike}>
        <BikesReview />
      </div>
      <div className="racquets_container" ref={benefitsSportsRacquet}>
        <Racquets />
      </div>
    </section>
  )
}

export default Servicos
