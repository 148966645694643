import { useState } from 'react'
import './styles.scss'

interface Props {
  name: string
  code: string
}
export const DropDownMob = ({ name, code }: Props) => {
  const [openContent, setOpenContent] = useState<boolean>(false)

  return (
    <section className="services-mobile pb-2">
      <div
        className={
          !openContent
            ? 'services-items_container-mob w-full border-solid border-white border-2'
            : 'services-items_container-mob w-full border-solid border-white border-2 bg-[#1e1e1e]'
        }
      >
        <button
          className={
            !openContent
              ? 'flex flex-col items-start justify-between w-full pb-0 px-3'
              : 'flex flex-col items-start justify-between w-full pb-5 px-3'
          }
          onClick={() => setOpenContent(!openContent)}
        >
          <div
            className={
              !openContent
                ? 'flex items-center justify-between w-full mb-0'
                : 'flex items-center justify-between w-full border-dashed border-b-[1px] border-[white] mb-5'
            }
          >
            <p className="px-0 py-5 text-base text-white font-roboto text-left">
              {name}
            </p>

            <span
              className={
                !openContent
                  ? 'services-mob-icon services-mob-icon_down'
                  : 'services-mob-icon'
              }
            />
          </div>
          {openContent && (
            <p className="text-white text-base px-0 py-1">Código:{code}</p>
          )}
        </button>
      </div>
    </section>
  )
}
