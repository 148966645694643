import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'

import BikeData from './bikeData.json'

interface Bike {
  logo: string
  text: string
}
export const BikesReview = () => {
  return (
    <section className="bikes-review_content font-roboto overflow-hidden bg-[rgba(0,0,0,.4)] py-0 px-[50px]">
      <div>
        <div>
          <h2 className="text-2xl font-bold text-white text-center uppercase mb-5 xl:mt-[75px] mt-[120px]">
            Revisão de bikes
          </h2>
          <p className="text-2xl text-white font-light text-center mb-7">
            Na revisão grátis constam os serviços abaixo:
          </p>
        </div>
        <div className="flex items-center vs:justify-center flex-wrap my-0 mx-auto max-w-[900px] w-full">
          {BikeData.map((data: Bike, index: number) => {
            return (
              <div
                className="bike-icons flex items-center justify-center flex-col w-full vs:max-w-[290px] max-w-[270px] mt-[30px] mb-[18px] mx-2"
                key={index}
              >
                <Image
                  src={stripUrl(data.logo)}
                  alt="bikes-services"
                  title="bikes-services"
                  loading="lazy"
                  width={68}
                  height={68}
                />
                <p className="bike-text text-white text-lg text-center my-[18px] mx-0 vs:mt-0">
                  {data.text}
                </p>
              </div>
            )
          })}
        </div>
        <div className="max-w-[900px] w-full mx-auto my-0">
          <p className="text-xs text-white text-center mb-5">
            *Os clientes Clube Decathlon que comprarem uma Bike das
            marcas/modeloss Btwin, Elops, Rockrider, Riverside, Triban, Van
            Rysel,Tilt ganham a primeira revisão gratuita. A revisão gratuita
            poderá ocorrer em até 90 dias contados da data de compra do produto,
            bem como está sujeita à apresentação do respectivo cupom fiscal. O
            cliente cadastrado receberá por e-mail as instruções para
            agendamento da revisão em até uma semana a partir da compra.
          </p>
          <p className="text-xs text-white text-center mb-20">
            Importante! Para garantir o recebimento do e-mail com as informações
            da revisão é preciso concordar em receber e-mails da Decathlon.
          </p>
        </div>
      </div>
    </section>
  )
}
